<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <div class="form-box">
      <div class="p-field p-grid">
        <label class="p-col-fixed"><i class="p-danger">*</i>设备编号：</label>
        <div class="p-col">
          <input-text :disabled="$route.query.did" v-model="form.did"></input-text>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-fixed"><i class="p-danger">*</i>设备名称：</label>
        <div class="p-col">
          <input-text v-model="form.dname"></input-text>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed"><i class="p-danger">*</i>设备坐标：</label>
        <div class="p-col" style="display: flex;">
          <input-text v-model="point" style="flex: 1"></input-text>
          <Button class="p-button-outlined point-but" @click="showSelectPoint">坐标设置</Button>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed"><i class="p-danger">*</i>设备地点：</label>
        <div class="p-col">
          <input-text v-model="prefixAddress"></input-text>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed"><i class="p-danger">*</i>详细地址：</label>
        <div class="p-col">
          <input-text v-model="form.address"></input-text>
        </div>
      </div>

      <div class="p-field p-grid">
        <label class="p-col-fixed"><i class="p-danger">*</i>设备类型：</label>
        <div class="p-col">
          <dropdown v-model="form.deviceType" :options="deviceTypeOptions" option-label="name"
                    option-value="value"></dropdown>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.deviceType===0">
        <label class="p-col-fixed"><i class="p-danger">*</i>appId：</label>
        <div class="p-col">
          <input-text v-model="form.appId"></input-text>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.deviceType===0">
        <label class="p-col-fixed"><i class="p-danger">*</i>appSecret：</label>
        <div class="p-col">
          <input-text v-model="form.appSecret"></input-text>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.deviceType===0">
        <label class="p-col-fixed"><i class="p-danger">*</i>设备编号：</label>
        <div class="p-col">
          <input-text v-model="form.deviceId"></input-text>
        </div>
      </div>

      <div class="p-field p-grid" v-if="form.deviceType===1">
        <label class="p-col-fixed"><i class="p-danger">*</i>域名：</label>
        <div class="p-col">
          <input-text v-model="form.url"></input-text>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.deviceType===1">
        <label class="p-col-fixed"><i class="p-danger">*</i>端口：</label>
        <div class="p-col">
          <input-text v-model="form.port"></input-text>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.deviceType===1">
        <label class="p-col-fixed"><i class="p-danger">*</i>用户名：</label>
        <div class="p-col">
          <input-text v-model="form.username"></input-text>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.deviceType===1">
        <label class="p-col-fixed"><i class="p-danger">*</i>密码：</label>
        <div class="p-col">
          <input-text type="password" v-model="form.password"></input-text>
        </div>
      </div>
      <div class="p-field p-grid" v-if="form.deviceType===1">
        <label class="p-col-fixed"><i class="p-danger">*</i>设备EUI：</label>
        <div class="p-col">
          <input-text v-model="form.deviceEui"></input-text>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed"></label>
        <div class="p-col">
          <Button :disabled="v$.$invalid" @click="onsubmit">保存</Button>
        </div>
      </div>
    </div>
    <select-map-point v-if="selectPointDisplay" ref="selectPointInst" @close="selectPoint"></select-map-point>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import SelectMapPoint from '@/views/modules/device/SelectMapPoint.vue';

export default {
  components: { SelectMapPoint },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      breadcrumb: {
        home: { label: "智能设备管理", to: "#" },
        items: [
          { label: "设备管理", to: "/device" },
          { label: "添加设备", to: "#" },
        ],
      },
      selectPointDisplay: false,
      form: {
        dname: null,
        ddescript: null,
        did: null,
        pid: null,
        longtitude: null,
        latitude: null,
        country: "china",
        province: null,
        city: null,
        district:null,
        address: null,
        deviceType: null,
        appId: null,
        appSecret: null,
        deviceId: null,
        url: null,
        port: null,
        username: null,
        password: null,
        deviceEui:null
      },
      deviceTypeOptions: [
        { name: '东方智感', value: 0 },
        { name: '矽递', value: 1 }
      ],
    }
  },
  computed: {
    prefixAddress() {
      return (this.form.province || '') + (this.form.city || '') + (this.form.district || '')
    },
    point() {
      return this.form.longtitude ? ((this.form.longtitude || '') + ',' + (this.form.latitude || '')) : ''
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        // this.$route.query.pid
        this.getDeviceData()
      }
    }
  },
  validations() {
    return {
      form: {
        dname: { required },
        did: { required },
        longtitude: { required },
        latitude: { required },
        country: { required },
        province: { required },
        deviceType: { required },
        appId: this.form.deviceType === 0 ? { required } : {},
        appSecret: this.form.deviceType === 0 ? { required } : {},
        deviceId: this.form.deviceType === 0 ? { required } : {},
        url: this.form.deviceType === 1 ? { required } : {},
        port: this.form.deviceType === 1 ? { required } : {},
        username: this.form.deviceType === 1 ? { required } : {},
        password: this.form.deviceType === 1 ? { required } : {},
        deviceEui: this.form.deviceEui === 1 ? { required } : {},
      }
    }
  },
  methods: {
    getDeviceData() {
      this.$http("/device/other/get", 'get', { pid: this.$route.query.pid, did: this.$route.query.did }, (res) => {
        this.form = res.data
      })
    },
    showSelectPoint() {
      this.selectPointDisplay = true;
      this.$nextTick(() => {
        this.$refs.selectPointInst.init();
      })
    },
    selectPoint(e) {
      if (e) {
        console.log(e)
        this.form.latitude = e.lat
        this.form.longtitude = e.lon
        this.form.province = e.province
        this.form.city = e.city
        this.form.district = e.district
      }
      this.selectPointDisplay = false
    },
    onsubmit() {
      this.$axios.post('/device/other/saveOrUpdate', this.form).then(res => {
        if (res.success) {
          this.$toast.add({
            severity: "success",
            summary: "成功",
            detail: "设备添加成功！",
            life: 3000,
          });
          this.$router.push("/device");
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.p-danger {
  color: red;
  margin-right: 4px;
}

.card {
  display: flex;
  justify-content: center;
}

.form-box {
  min-width: 460px;

  .p-col-fixed {
    min-width: 120px;
    justify-content: flex-end;
  }

  .p-dropdown, .p-inputtext {
    width: 100%;
  }

  .point-but {
    margin-left: 10px;
    height: 2.2rem;
  }

}
</style>
