<template>
  <Dialog v-model:visible="display" :header="title" :content-style="{padding:0}" @close="submit(false)">
    <div id="map">
      <div class="search-box">
        <input-text v-model="searchText"></input-text>
        <Button icon="pi pi-search" @click="searchAddress"></Button>
      </div>
      <div id="searchPanel"></div>
    </div>

    <template #footer>
      <div class="dialog-footer">
        <Button class="p-button-outlined" @click="clearPoint">清除坐标</Button>
        <div>
          <Button @click="submit(true)">确定</Button>
          <Button class="p-button-outlined" @click="submit(false)">取消</Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { nextTick, reactive, ref } from 'vue';

export default {
  setup() {
    const display = ref(false)
    const title = ref("坐标位置")
    const data = reactive({
      lon: null,
      lat: null,
      province: null,
      city: null,
      district: null
    })

    let placeSearch, mapInst;
    const init = () => {
      display.value = true;
      nextTick(() => {
        mapInst = new AMap.Map("map", {
          resizeEnable: true,
          center: [116.400274, 39.905812],
          layers: [
            new AMap.TileLayer.RoadNet(),
            new AMap.TileLayer.Satellite()
          ],
          zoom: 11
        });

        mapInst.on('click', (e) => {
          mapInst.getCity((info) => {
            data.city = info.city
            data.province = info.province
            data.district = info.district
            data.lon = e.lnglat.lng
            data.lat = e.lnglat.lat
            title.value = '坐标位置-' + info.province + info.city + info.district + ' (' + e.lnglat.lng + ',' + e.lnglat.lat + ')';
          })
        })

        placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          city: "010", // 兴趣点城市
          citylimit: false,  //是否强制限制在设置的城市内搜索
          map: mapInst, // 展现结果的地图实例
          panel: "searchPanel", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围,
          extensions: "all"
        })
      })

    }

    const searchText = ref(null)
    const searchAddress = () => {
      placeSearch.search(searchText.value, (status, result) => {
        console.log(result)
      })
    }
    const clearPoint = () => {
      data.lon = null;
      data.city = null;
      data.province = null;
      data.district = null;
      data.lat = null;
      title.value = "坐标位置"
    }

    const destroyMap = () => {
      mapInst.destroy()
      display.value = false
    }

    return { init, display, title, searchText, searchAddress, clearPoint, destroyMap, data }
  },
  methods: {
    submit(e) {
      this.$emit('close', e ? this.data : null)
      this.destroyMap()
    }
  }
}

</script>

<style scoped lang="scss">
#map {
  width: 60vw;
  height: 60vh;
  position: relative;
}

#searchPanel {
  width: 360px;
  max-height: 500px;
  background: #FFFFFF;
  position: absolute;
  right: 10px;
  top: 10px;
  overflow-y: auto;
  z-index: 9999;
}

.search-box {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9999;
  background-color: white;

  .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .p-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
